export default {
    getCookie(name) {
        var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
        if (arr = document.cookie.match(reg)) {
            // return unescape(arr[2]);
            return arr[2];
        } else {
            return null;
        }
    },
    setCookie(cookieName,cookieValue){
        var d = new Date();
        d.setHours(d.getHours()+cookieDates);
        var cookieDates = "expires=" + d.toUTCString();
        document.cookie = cookieName + "=" + cookieValue + "; " + 12+"; path=/"
    }
}