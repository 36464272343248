<template>
	<div id="head">
		<div class="topBox">
			
			<img @click="tojm()" src="../assets/image/logo.png" alt="">
			<van-icon @click="isNav = !isNav" color="#fff" size="22" :name="isNav?'cross':'more-o'" />
			<!-- <van-icon color="#fff" size="22" name="contact" /> -->
		</div>

		<div class="navBox" v-show="isNav">
			<div class="navItem">
				<p>
					<router-link to="/">
						<span>Home</span>
						<van-icon size="20" name="arrow" />
					</router-link>
				</p>
			</div>
			<div class="navItem">
				<p @click="Services = !Services">
					<span>Services</span>
					<van-icon size="20" :name="Services?'arrow-down':'arrow'" />
				</p>
				<div class="subNav" v-show="Services">
					<div @click="caseShow = !caseShow">
						<span>3D Printing</span>
						<van-icon size="18" :name="caseShow?'arrow-down':'arrow'" />
						<div v-show="caseShow" class="caseBox" >
							<router-link to="/sla">SLA</router-link>
							<router-link to="/projet">Projet</router-link>
							<router-link to="/sls">SLS</router-link>
							<router-link to="/dmls">DMLS</router-link>
							<router-link to="/fdm">FDM</router-link>
							<router-link to="/mjf">MJF</router-link>
						</div>
					</div>
					<p><router-link to="/cnc">CNC Machining</router-link></p>
					<p><router-link to="/mould">Injection Molding</router-link></p>
					<p><router-link to="/metal">Sheet Metal</router-link></p>
					<p><router-link to="/complex">Vacuum Casting</router-link></p>
					<p><router-link to="/surface">Surface finishing</router-link></p>
				</div>
			</div>
			<!-- <div class="navItem">
				<p @click="Resources = !Resources">
					<span>Resources</span>
					<van-icon size="20" :name="Resources?'arrow-down':'arrow'" />
				</p>
				<div class="subNav" v-show="Resources">
					<p><router-link to="">Design guides</router-link></p>
					<p><router-link to="">Materials</router-link></p>
					<p><router-link to="">Applications</router-link></p>
					<p><router-link to="/blog">Blog</router-link></p>
					<p><router-link to="">Videos</router-link></p>
				</div>
			</div> -->
			<!-- <div class="navItem">
				<p @click="About = !About">
					<span>About</span>
					<van-icon size="20" :name="About?'arrow-down':'arrow'" />
				</p>
				<div class="subNav" v-show="About">
					<p><router-link to="">About us</router-link></p>
					<p><router-link to="">Ip protection</router-link></p>
					<p><router-link to="">Process</router-link></p>
					<p><router-link to="">Quality</router-link></p>
					<p><router-link to="">Partner program</router-link></p>
				</div>
			</div> -->
			<!-- <div class="navItem">
				<p><router-link to="/">Contact Us</router-link></p>
			</div> -->

			<!-- <router-link class="modeling" to="">3D modeling</router-link>
			<router-link class="quote" to="">Get instant quote</router-link> -->
		</div>
		
	</div>
    
	
</template>

<script>

export default {
	name: 'myHead',
    props: {
      	msg: String
	},
	data () {
        return {
			isNav:false,
			subHead: false,
			subHeadText:'',
			Services:false,
			Resources:false,
			About:false,
			caseShow:false,
        }
    },
	watch: {
		$route(to, from) {
			this.$route.meta.subHead?this.subHead=true:this.subHead=false
			this.subHeadText = this.$route.meta.title
			this.isNav = false
		}
	},
	methods:{
		tojm(){
				this.$router.push({
				path: '/',
			})
		},
		back () {
            this.$router.go(-1)
        },
		home(){
			this.$router.push({
				path: '/',
			})
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	#head {
		background-color: #222;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 99;
		.topBox {
			align-items: center;
			background-color: #000000;
			width: 100%;
			height: 1rem;
			line-height: 1rem;
			padding: 0 .3rem;
			display: flex;
			justify-content: space-between;
			position: absolute;
			top: 0;
			left: 0;
			img {
				width: .58rem;
				height: .68rem;
				// position: absolute;
				// left: 50%;
				// top: 50%;
				// transform: translate(-50%,-50%)
			}
			// .van-icon-contact {
			// 	position: absolute;
			// 	right: .1rem;
			// 	top: 50%;
			// 	transform: translate(-50%,-50%)
			// }
			// .van-icon-cross,.van-icon-more-o {
			// 	position: absolute;
			// 	left: .5rem;
			// 	top: 50%;
			// 	transform: translate(-50%,-50%)
			// }
		}
		.navBox {
			width: 100%;
			height: 100vh;
			overflow: auto;
			background-color: #222222;
			padding: 0 .4rem;
			color: #fff;
			padding-bottom:5px; 
			padding-bottom: 0.8rem;
			position: absolute;
			// border-bottom:1px solid black; 
			box-shadow:0px 15px 10px -15px #000;
			top: 1rem;
			.navItem {
				border-bottom: 1px solid #666666;
				font-size: .3rem;
				>p {
					width: 100%;
					line-height: 1.25rem;
					.van-icon {
						float: right;
						margin-top: 0.45rem;
					}
					a {
						color: #fff;
						display: block;
						width: 100%;
						height: 100%;
					}
				}
				.subNav {
					padding-left: 0.12rem;
					>p,>div {
						line-height: 0.8rem;
						font-size: 12px;
						width: 100%;
						.van-icon {
							float: right;
							margin-top: 0.2rem;
						}
						a {
							display: block;
							width: 100%;
							height: 100%;
							color: #ccc;
						}
						.caseBox {
							a {
								display: inline-block;
								width: 3.2rem;
								height: 0.8rem;
								background-color: #333;
								color: #999;
								padding-left: 0.3rem;
								margin-bottom: 0.05rem;
								margin-right: 0.05rem;
								&:nth-child(2n) {
									margin-right: 0;
								}
							}
						}
					}
				}
			}
		}
		.navBox{
			transition: 0.25s;
		}
		.modeling,.quote {
			display: block;
			margin: 0 auto;
			width: 6.7rem;
			line-height: 0.88rem;
			text-align: center;
		}
		.modeling {
			background-color: #fff;
			color: #5252F2;
			margin-top: 3rem;
			margin-bottom: 0.45rem;
		}
		.quote {
			background-color: #5252F2;
			color: #fff;
		}
	}
	
</style>
