import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
		path: '/',
		name: 'Home',
		// component: Home
		component: () => import(/* webpackChunkName: "about" */ '@/views/home/home.vue'),
		meta: {
			requireLogin: false
		},
    },
	{
		path: '/sla',
		name: 'sla',
		component: () => import(/* webpackChunkName: "about" */ '@/views/sla/sla.vue'),
		meta: {
			requireLogin: false
		},
    },
	{
		path: '/projet',
		name: 'projet',
		component: () => import(/* webpackChunkName: "about" */ '@/views/projet/projet.vue'),
		meta: {
			requireLogin: false
		},
    },
	{
		path: '/mjf',
		name: 'mjf',
		component: () => import(/* webpackChunkName: "about" */ '@/views/mjf/mjf.vue'),
		meta: {
			requireLogin: false
		},
    },
	{
		path: '/fdm',
		name: 'fdm',
		component: () => import('@/views/fdm/fdm.vue'),
		meta: {
			requireLogin: false
		},
    },
	{
		path: '/sls',
		name: 'sls',
		component: () => import('@/views/sls/sls.vue'),
		meta: {
			requireLogin: false
		},
    },
	{
		path: '/dmls',
		name: 'dmls',
		component: () => import('@/views/dmls/dmls.vue'),
		meta: {
			requireLogin: false
		},
    },
	{
		path: '/metal',
		name: 'metal',
		component: () => import('@/views/metal/metal.vue'),
		meta: {
			requireLogin: false
		},
    },
	{
		path: '/cnc',
		name: 'cnc',
		component: () => import('@/views/cnc/cnc.vue'),
		meta: {
			requireLogin: false
		},
    },
	{
		path: '/mould',
		name: 'mould',
		component: () => import('@/views/mould/mould.vue'),
		meta: {
			requireLogin: false
		},
    },
	{
		path: '/complex',
		name: 'complex',
		component: () => import('@/views/complex/complex.vue'),
		meta: {
			requireLogin: false
		},
    },
	{
		path: '/surface',
		name: 'surface',
		component: () => import('@/views/surface/surface.vue'),
		meta: {
			requireLogin: false
		},
    },
	{
		path: '/designGuide',
		name: 'designGuide',
		component: () => import('@/views/designGuide/designGuide.vue'),
		meta: {
			requireLogin: false
		},
    },
	{
		path: '/blog',
		name: 'blog',
		component: () => import('@/views/blog/blog.vue'),
		meta: {
			requireLogin: false,
			hideFoot:true,
		},
    },
	{
		path: '/application',
		name: 'application',
		component: () => import('@/views/application/application.vue'),
		meta: {
			requireLogin: false,
		},
    },
]

const router = new VueRouter({
  	routes
})

export default router
