<template>
	<div class="myFoot">
         <div class="itemBox">
            <p class="title">Services</p>
            <router-link to="/sla">SLA</router-link>
            <router-link to="/sls">SLS</router-link>
            <router-link to="/fdm">FDM</router-link>
               <router-link to="/dmls">DMLS</router-link>
            <router-link to="/projet">Projet</router-link>
          
        </div>
        <div class="itemBox" style="margin-top:-.65rem;">
           
            <router-link to="/mjf">MJF</router-link>
            <router-link to="/cnc">CNC Machining</router-link>
            <!-- <router-link to="">Injection Molding</router-link> -->
            <router-link to="/metal">Sheet Metal</router-link>
            <!-- <router-link to="">Vacuum Casting</router-link> -->
            <router-link to="/surface">Surface finishing</router-link>
        </div>
        <!-- <div class="itemBox">
            <p class="title">About</p>
            <router-link to="">About us</router-link>
            <router-link to="">Ip protection</router-link>
            <router-link to="">Prcoess</router-link>
            <router-link to="">Quality</router-link>
            <router-link to="">Partner program</router-link>
        </div> -->
        <!-- <div class="itemBox">
            <p class="title">Resources</p>
            <router-link to="">Design guides</router-link>
            <router-link to="">Materials</router-link>
            <router-link to="/application">Applications</router-link>
            <router-link to="/blog">Blog</router-link>
            <router-link to="">Videos</router-link>
        </div> -->
       
        <!-- <div class="itemBox">
            <p class="title">Quote</p>
            <router-link to="">Quote</router-link>
        </div> -->
        <!-- <div class="itemBox">
            <p class="title">Contact</p>
            <router-link to="">Contact us</router-link>
        </div> -->
        <div class="copyrightBox">
            <span>Copyright © 2020 Qisiyin</span>
            <span>
                <img src="../assets/image/share1.png" mode="" />
                <img src="../assets/image/share2.png" mode="" />
                <img src="../assets/image/share3.png" mode="" />
                <img src="../assets/image/share4.png" mode="" />
            </span>
        </div>
	</div>

</template>

<script>
export default {
    name: 'myFoot',
    data () {
        return {
            
        }
    },

    watch: {
        
    },

    methods: {
        
    },

    created () {

    },

    mounted () {
        
    }
}
</script>

<style lang="scss">
    .myFoot {
        padding: .7rem 0 0 0rem;
        background-color: #222222;
        overflow: hidden;
        .itemBox {
            font-size: .24rem;
            width: 50%;
            display: inline-block;
            margin-bottom: 0.5rem;
            padding-left: 0.8rem;
            &:nth-child(2n) {
                padding-left: .5rem;
            }
            .title {
                color: #fff;
                margin-bottom: 0.3rem;
                font-weight: 600;
            }
            a {
                display: block;
                color: #666666;
                margin-bottom: 0.3rem;
            }
        }
        .copyrightBox {
            line-height: 1.4rem;
            border-top: 1px solid #333333;
            color: #666;
            font-size: .24rem;
            padding: 0 .4rem;
            span {
                &:nth-child(2) {
                    float: right;
                }
                img {
                    width: 0.36rem;
                    height: 0.36rem;
                    margin-left: 0.3rem;
                }
            }
            
        }
    }
</style>
