<template>
	<div :class="{'subHead':subHead==true}" id="app">
		<myHead/>
		
		<router-view/>
		<myFoot v-if="hideFoot"/>
	</div>
</template>

<style lang="scss">
@import './common/common.scss';

#app {
	// font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #333;
	padding-top: 1rem;
	// padding-bottom: 1rem;
}
#app.subHead {
	padding-top: .9rem;
}
</style>

<script>
export default {
	name: 'App',
	components: {
		
	},
	data () {
		return {
			hideFoot: false,
			subHead:false,
		}	
	},
	watch: {
		$route(to, from) {
			this.$route.meta.hideFoot ? this.hideFoot = false : this.hideFoot = true
			this.$route.meta.subHead?this.subHead=true:this.subHead=false
		}
	},
	methods: {
		
	},
	created () {

	}
}
</script>
