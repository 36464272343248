import Vue from 'vue'
import Vant from 'vant';
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import request, { baseURL } from './request/request'
import myHead from '@/components/myHead'
import myFoot from '@/components/myFoot'

import common from '@/common/common.js'

import 'vant/lib/index.css';
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: '8yYf3ywN6SLUToxURI2XIP3UTavSTYXL'
})

// 获取url参数
Vue.prototype.$utils=function(name){
	return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[,""])[1].replace(/\+/g,'%20'))||null;
}

Vue.config.productionTip = false

Vue.prototype.$common = common
Vue.prototype.$request = request
Vue.prototype.$baseURL = baseURL

function getCookie(name) {
  var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if (arr = document.cookie.match(reg))
      return unescape(arr[2]);
  else
      return null;
}

function setCookie(cname, cvalue, exdays) {
  //cname 名字
  //cvalue 值
  //exdays 时间            0.01大概25分钟
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires+"; path=/"
}
Vue.use(Vant);
Vue.prototype.$token = getCookie('_token')
Vue.component('myHead', myHead)
Vue.component('myFoot', myFoot)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

//用于判断是否登录了，否则跳转到登录页
router.beforeEach((to, from, next) => {
	// console.log(from);
	if (to.matched.some(record => record.meta.requireLogin)){  // 判断该路由是否需要登录权限
		if(to.query.token){
			setCookie('_token', to.query.token, 0.288)
		}
		if (getCookie('_token') || to.query.token) {  // 判断当前用户的登录token是否存在
			next();
		} else {
			next({
				path: '/login',
				query: {
					fullPath: to.fullPath
				}
			})
		}
	}else {
		next();
	}
})

